@import "~ui-components-rollup/src/components/colors";

.base {
  background-color: $background-grey;
  padding-top: 35px;
  min-height: 100vh;
  padding-bottom: 35px;
  transition: padding-left 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  min-width: 1260px;
}

.with-nav-menu {
  padding-left: 150px;

  :global {
    .nav-adjusted-fixed-pane {
      width: calc(100% - 150px) !important;
    }
  }
}
.load-page {
  width: 60%;
  margin: auto;
  text-align: center;
  padding-top: 15vh;
  font-family: Arial, Helvetica, sans-serif;
  animation: fadeIn linear 3s;
  -webkit-animation: fadeIn linear 3s;
  -moz-animation: fadeIn linear 3s;
  -o-animation: fadeIn linear 3s;
  -ms-animation: fadeIn linear 3s;
}
.loader-svg {
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 20vh;
}


@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
