

.status {
    display: flex;
    align-items: center;
margin: auto;  
    .circle {
      margin-right: 6px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
  
      &.regular{
        background: #27d397;
      }
      &.medium{
          background: orange;
      }
      &.high {
        background: red;
      }
      &.noStatus{
        background: gray;
        opacity: 0.3;
      }
    }
  }