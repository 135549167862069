@use "~ui-components-rollup/src/components/layout";
@use "~ui-components-rollup/src/components/colors";

h1 {
  font-size: 60px;
  line-height: 1.4;
  font-weight: lighter;
  color: colors.$text-grey;
  margin: 0;

  @media screen and (max-width: layout.$break-medium) {
    font-size: 45px;
  }
}

h2 {
  font-size: 30px;
  font-weight: lighter;
  margin: 0;
  color: colors.$text-grey;

  @media screen and (max-width: layout.$break-medium) {
    font-size: 22px;
  }
}

p {
  font-size: 16px;
  line-height: 1.67;
  font-weight: normal;
  color: colors.$text-grey;
  padding: 0;
  margin: 0;

  @media screen and (max-width: layout.$break-medium) {
    font-size: 14px;
  }
}

div.error-text {
  color: colors.$error-red;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: bolder;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    top: 3px;
    position: relative;
  }

  &.warning {
    svg {
      fill: colors.$warning-orange;
    }
  }

  svg {
    width: 16px;
    fill: colors.$error-red;
  }
}


.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.selected-marker {
  width: 0;
  left: 50%;
  height: 4px;
  position: absolute;
  top: 40px;
  background-color: colors.$primary-blue;
}

.active .selected-marker {
  display: block;
  width: 65%;
  left: 17.5%;
  transition: all 0.25s ease-out;
}

.feature-button {
  color: colors.$primary-blue;
  letter-spacing: 0.2px;
  line-height: 1.86;
  font-weight: bolder;
  font-size: 14px;
  float: right;
  margin-left: 66px;
  position: relative;

  &:hover {
    color: #173F68;

    svg {
      fill: #173F68;
    }
  }

  svg {
    fill: colors.$primary-blue;
    position: absolute;
    left: -26px;
    top: 2px;
  }
}


//anchor
a {
  text-decoration: none;
  &:hover, &:visited{
    text-decoration: none;
  }
  &.button{
    display: inline-block;
  }
}


//button
button{
  font-family: 'Open Sans', sans-serif;
  border: none;
  &:focus{
    outline: none;
  }
  &:hover{
    cursor: pointer;
  }
}


//---shared properties---
button, a.button{
  border-radius: 0;
  text-align: center;
  color: #fff;
  font-size: 14px;
}
