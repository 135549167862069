.map {
  margin: auto;
  display: block;
  padding-top: 50px;
  height: 80%;
  width: 70%;
}

.loader-svg {
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 20vh;
}
