@import "~ui-components-rollup/src/components/colors";


.container {
  display: flex;
}

.link {
  height: 100%;
  line-height: 1.33;
  font-size: 15px;
  color: $white;
  align-items: center;
  display: flex;
  margin: 0 20px 0;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: calc(100% + 16px);
    height: 4px;
    left: -8px;
    transform: scaleX(0);
    background-color: #1fa0ff;
  }

  &:hover::after {
    transform: scaleX(1);
    transition: transform 0.4s cubic-bezier(.25, .8, .25, 1);
  }
}

.active-link {
  color: #1fa0ff;
  font-weight: 600;

  &::after {
    transform: scaleX(1);
  }
}

