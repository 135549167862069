@import "~ui-components-rollup/src/components/colors";

.slider-style {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;

  @media (max-width: 1280px) {
    margin-top: 20px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10vh;
  margin: auto;
  width: 80%;
  border-bottom: solid 1px rgba(161, 161, 161, 0.507);
  font-size: medium;
}

.account-icon {
  position: fixed;
  margin-right: 50px;
}

.text-style {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  "Helvetica Neue", sans-serif;
  margin-bottom: 10px;
}

.button {
  width: 100px;
  height: 30px;
  margin: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.exit-button {
  color: $dark-blue;
  position: fixed;
  right: 22vw;
  top: 100px;
  @media (max-width: 1360px) {
    right: 93vw;
  }
}

.svg-style {
  height: 20px;
  width: 20px;
  cursor: pointer;
  fill: $dark-blue;
}
