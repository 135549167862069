@import "~ui-components-rollup/src/components/colors";

.root-header-style {
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: $header-blue;
  box-shadow: 0 0 5px 0 #000000;
  float: right;

  .svg-style {
    height: 35px;
    width: 30px;
    cursor: pointer;
    fill: #ffffffd3;
  }

  .svg-position {
    float: right;
    margin: 17px 30px 0 0;
  }

  .border {
    height: 40px;
    border-left: solid $white-grey 1px;
    float: right;
    margin: 15px 25px 0 0;
  }

  .account-name {
    font-size: large;
    color: white;
    float: right;
    margin: 22px 25px 0 0;
  }

  .expand-button {
    height: 500px;
    width: 500px;
  }

  .arrow-icon {
    margin: 10px 0 0 15px;
    height: 50px;
    width: 50px;
    transform: rotate(230deg);
  }
}

.tab-style {
  position: fixed;
  left: 130px;
  top: 3px;
}

