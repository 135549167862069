@import "~ui-components-rollup/src/components/colors";

.slider-style {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 90px;

  .nav-header {
    height: 75px;
    box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 30px;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 3;
  }

  .exit-button-svg-style {
    height: 20px;
    width: 20px;
    cursor: pointer;
    fill: $dark-blue;
  }

  .svg-style {
    height: 60px;
    width: 60px;
    cursor: pointer;
    fill: black;
  }

  .svg-position {
    float: right;
    margin: 17px 30px 0 0;
  }

  .select-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 5vh;
  }

}
