@import "~ui-components-rollup/src/components/colors";

body {
  background-color: $grey-2;
  height: 100%;
  width: 100%;
  overflow: auto !important;

  .rt-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: 7vh auto auto auto;
    background-color: $grey-2;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .header-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 7vh;
        font-size: 5vh;
        margin: 5px;
        font-weight: bold;
      }

      .header-line {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 19vw;
        border-bottom: 2px solid $grey-5;
        margin: auto;
      }

      .summery-table {
        width: 100%;
        margin: 5vh 0 5vh 0;
      }
    }

    .table-widget {
      display: grid;
      height: 11vh;
      width: 100%;
      z-index: 2; //change z from 48
      position: sticky;
      top: 4vh;
      background-color: $grey-2;
      grid-template-columns: 1fr 400px;
      padding-top: 5vh;
      outline: 1px solid $grey-2;

      .table-counter {
        font-size: 150%;
      }

      svg {
        transform: translateX(-6px);
      }
    }

    .table-animation {
      animation: fadeInAnimation ease 2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
.loader-svg {
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 20vh;
}