@use "~ui-components-rollup/src/components/colors";
@use "~ui-components-rollup/src/components/zindex";
@use "~ui-components-rollup/src/components/layout";

.base {
  height: 64px;
  background-color: colors.$header-blue;
  padding-left: 10px;
  z-index: zindex.$tab-header-z;
  display: flex;
}

.service {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 60px;

  svg {
    width: 30px;
    height: 30px;
    margin-right: 16px;

    [class*="cc-service-icon-fill"],
    [class*="primary-fill"] {
      fill: colors.$white;
    }

    [class*="cc-service-icon-stroke"],
    [class*="primary-stroke"] {
      stroke: colors.$white;
    }
  }

  span {
    font-size: 20px;
    line-height: 1.35;
    color: colors.$white;
  }
}

.tab-widgets {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;

  > :not(:last-child) {
    margin-right: 32px;
  }
}

