@use "~ui-components-rollup/src/components/zindex";
@use "~ui-components-rollup/src/components/mixins";

.base {
  position: fixed;
  z-index: zindex.$full-widget-z;
  width: 25%;
  right: 0;
  height: 100%;
  top: 72px;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: #f8f8f8;

  @include mixins.small {
    width: 1280px;
  }

  @media (max-width: 1280px) {
    width: 90%;
    margin-right: 5%;
  }
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: zindex.$full-widget-mask-z;
  background-color: rgba(0, 0, 0, 0.4);
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 0.6s cubic-bezier(.25, .8, .25, 1);
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(105%);
  transition: transform 0.6s cubic-bezier(.25, .8, .25, 1);
}

.management-widget-open {
  overflow: hidden;

  [class*="Notification-module_global"] {
    z-index: zindex.$notifications-z + zindex.$full-widget-z;
    left: initial;
    right: 684px;
    transform: translateX(50%);
  }

}

