body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  overflow-y: auto;
  scrollbar-color: #ccc #eee;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

input,
textarea,
select,
button {
  font-family: "Open Sans", sans-serif;
}

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

pre {
  margin: 0;
}

.right {
  float: right;
}

.left {
  float: left;
}

ul,
li {
  margin: 0;
  padding: 0;
}

body.marketing-mode {
  overflow: hidden;
}

a[disabled] {
  pointer-events: none;
}

.transparent-hide {
  opacity: 0;
}
